<template>
    <div class="card card-primary card-outline">
        <div class="card-body box-profile">
            <ul class="list-group list-group-unbordered mb-3">
                <li class="list-group-item" style="border:none">
                    <label>
                       Tipos del servicio
                    </label>
                    <MultiSelect
                        :maxSelectedLabels="2"
                        v-model="idsAsignados"
                        :options="tiposDisponibles"
                        optionValue="id"
                        optionLabel="nombre"
                        placeholder="Tipos"
                        class="w-full md:w-20rem fixed-size"
                    />
                </li>
            </ul>
        </div>
    </div>



</template>

<script>

import { PwgsApi } from '../../../../services/PwgsApi';
import MultiSelect from 'primevue/multiselect';

export default ({
    props: ['id'],
    components: {
        MultiSelect,
    },
    setup() {
        return; 
    },
    data() {
        return {
            tiposDisponibles:[],         
            idsAsignados:[],  
        }
    },
        methods: {
            async obtenerTiposDisponibles(){
                try {
                    const api = new PwgsApi();
                    const aux = await api.get('servicios/' + this.$props.id+'/datos-basicos');
                    this.tiposDisponibles = aux.tipos_servicio_disponibles;
                    var tiposAsignados = aux.tipos_servicio_asignados_array;
                    this.idsAsignados = Object.keys(tiposAsignados);
                }catch{
                    this.tiposDisponibles = [];
                    this.idsAsignados = [];

                }
            },
            async obtenerTiposAsignados(){
            /*    try{
                    const api = new PwgsApi();
                    const aux = await api.get('servicios/' + this.$props.id+'/datos-basicos');
                    var tiposAsignados = aux.tipos_servicio_asignados_array;
                    this.idsAsignados = Object.keys(tiposAsignados);
                }catch (e) {
                    this.idsAsignados = [];
                }
                */
            },
            async accionMarcar(id) {
                const api = new PwgsApi();
                const body = {"id_tipo_servicio":id, "estado_tipo_servicio":1};
                await api.put('servicios/' + this.$props.id+'/modificar-servicio',body);
            },
           
            async accionDesmarcar(id) {
                const api = new PwgsApi();
                const body = {"id_tipo_servicio":id, "estado_tipo_servicio":0};
                await api.put('servicios/' + this.$props.id+'/modificar-servicio',body);
            }
    },

    activated() {
        this.obtenerTiposAsignados();
        this.obtenerTiposDisponibles();
    },
    
    watch: {
        idsAsignados(newValue, oldValue) {
            this.oldIdsAsignados = [...oldValue]; // Guardar los valores anteriores

            // Calcular los elementos que se han marcado y desmarcado
            const marcados = newValue.filter(id => !oldValue.includes(id));
            const desmarcados = oldValue.filter(id => !newValue.includes(id));

            // Ejecutar las acciones para los marcados
            marcados.forEach(id => {
                this.accionMarcar(id);
            });

            // Ejecutar las acciones para los desmarcados
            desmarcados.forEach(id => {
                this.accionDesmarcar(id);
            });
    }
    }
})
</script>