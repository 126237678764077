<template>
  <!-- Datos Compñí­a -->
  <div :style="estilo">
    <div class="card bg-gradient-primary" :style="miestilo">
      <div class="card-body box-profile position-relative">
        <ConfirmDialog :style="{width: '500px'}" />
        <div title="Estado del servicio." v-if="mostrarEstado && servicio.estado=='Activo'"
          class="ribbon ribbon-bottom-left"><span style="background-color: #6c757d">{{ servicio.estado }}</span>
        </div>
        <div title="Estado del servicio." v-if="mostrarEstado && servicio.estado=='Finalizado'"
          class="ribbon ribbon-bottom-left"><span style="background-color: #28a745">{{ servicio.estado }}</span>
        </div>
        <div title="Estado del servicio." v-if="mostrarEstado && servicio.estado=='Eliminado'"
          class="ribbon ribbon-bottom-left"><span style="background-color: #ffc107;">{{ servicio.estado }}</span>
        </div>
        <div title="" v-if="mostrarEstado && conflictivo"
          class="ribbon ribbon-bottom-right"><span style="background-color: #ffc107;">Conflictivo</span>
        </div>
        <div v-if="reasignado && mostrarfecha && finalizado"
          style="display: flex;justify-content: center;align-items: center ; gap: 5px;">
          <h3 class="profile-username text-center">{{servicio.codigo}}</h3>
          <h3 title="Reasignado" class="profile-username text-center" style="color:red;">R</h3>
          <button @click="confirmar2()" type="button" class="btn btn-sm btn-success recuperar" title="Recuperar"><i
              class="fa fa-recycle"></i></button>
          <!-- <button @click="confirmar1()" type="button" class="btn btn-light btn-sm" style="border: 1px solid grey; font-size: small;" title="Reasignar">R</button>-->
        </div>
        <div v-else-if="reasignado && mostrarfecha"
          style="display: flex;justify-content: center;align-items: center ; gap: 5px;">
          <h3 class="profile-username text-center">{{servicio.codigo}}</h3>
          <h3 title="Reasignado" class="profile-username text-center" style="color:red;">R</h3>
          <!--<button @click="confirmar1()" type="button" class="btn btn-light btn-sm"
            style="border: 1px solid grey; font-size: small;" title="Reasignar">R</button>-->
        </div>
        <div v-else-if="finalizado && mostrarfecha"
          style="display: flex;justify-content: center;align-items: center ; gap: 5px;">
          <h3 class="profile-username text-center">{{servicio.codigo}}</h3>
          <button @click="confirmar2()" type="button" class="btn btn-sm btn-success recuperar" title="Recuperar"><i
              class="fa fa-recycle"></i></button>
          <!--<button @click="confirmar1()" type="button" class="btn btn-light btn-sm"
            style="border: 1px solid grey; font-size: small;" title="Reasignar">R</button>-->
        </div>
        <div v-else-if="eliminado && mostrarfecha"
          style="display: flex;justify-content: center;align-items: center ; gap: 5px;">
          <h3 class="profile-username text-center">{{servicio.codigo}}</h3>
          <button @click="confirmar2()" type="button" class="btn btn-sm btn-success recuperar" title="Recuperar"><i
              class="fa fa-recycle"></i></button>
          <!--  <button @click="confirmar1()" type="button" class="btn btn-light btn-sm"
            style="border: 1px solid grey; font-size: small;" title="Reasignar">R</button>-->
        </div>
        <div v-else-if="mostrarfecha" style="display: flex;justify-content: center;align-items: center ; gap: 5px;">
          <h3 class="profile-username text-center">{{servicio.codigo}}</h3>
         <!-- <button @click="confirmar1()" type="button" class="btn btn-light btn-sm"
            style="border: 1px solid grey; font-size: small;" title="Reasignar">R</button>-->
        </div>
        <p style="margin: 0;" v-if="mostrarfecha" class="text-muted text-center text-white">{{servicio.codigo_original}}</p>
        <!--<p style="font-size: 12px;" v-if="mostrarfecha && servicio.contrato" class="text-muted text-center text-white"><b>Contrato: </b>{{ servicio.contrato }}</p>-->
          <!--<p v-if="mostrarfecha" class="text-muted text-center text-white"><b>F. asig: </b>{{ fecha1 }}</p>-->
          <!--<p v-if="mostrarfecha" class="text-muted text-center text-white"><b>Dir: </b>{{ asegurado.direccion}}</p>-->
          <!--<p v-if="mostrarfecha && servicio.distancia" class="text-muted text-center text-white"><b>Dist: </b>{{ kms }}</p>-->
          <div v-if="this.satisfaccion!=-1" class="d-flex justify-content-center satisf" @click="mostrarExplicacion = !mostrarExplicacion"
            @mouseover="showDialog" @mouseleave="hideDialog">
            <div title="Satisfacción del Expediente" v-if="this.satisfaccion == '4'"><svg
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                <defs>
                  <clipPath id="__lottie_element_2">
                    <rect width="1200" height="1300" x="0" y="0"></rect>
                  </clipPath>
                </defs>
                <g clip-path="url(#__lottie_element_2)">
                  <g transform="matrix(1,0,0,1,99.7659912109375,146.0260009765625)" opacity="1" style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,500.2340087890625,503.9739990234375)">
                      <path fill="rgb(13,170,93)" fill-opacity="1"
                        d=" M0,-499.989990234375 C141.947998046875,-499.989990234375 270.1050109863281,-440.80499267578125 361.12799072265625,-345.7720031738281 C447.1440124511719,-255.9669952392578 500,-134.1490020751953 500,-0.009999999776482582 C500,123.18599700927734 455.4230041503906,235.98599243164062 381.52301025390625,323.1419982910156 C289.7980041503906,431.32000732421875 152.89999389648438,499.989990234375 0,499.989990234375 C-148.9510040283203,499.989990234375 -282.7170104980469,434.8210144042969 -374.3269958496094,331.4429931640625 C-452.5190124511719,243.20700073242188 -500,127.13500213623047 -500,-0.009999999776482582 C-500,-138.05599975585938 -444.0190124511719,-263.0530090332031 -353.5270080566406,-353.5369873046875 C-263.0379943847656,-444.01800537109375 -138.0399932861328,-499.989990234375 0,-499.989990234375z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,402.0799560546875,703.9949951171875)" opacity="1"
                    style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,220.6199951171875,107.58100128173828)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M153.87899780273438,-105.43900299072266 C154.41000366210938,-106.81999969482422 156.17599487304688,-107.03700256347656 156.8300018310547,-105.70999908447266 C167.44700622558594,-84.16200256347656 220.36900329589844,38.513999938964844 70.15299987792969,76.79000091552734 C-49.08599853515625,107.33000183105469 -195.8470001220703,6.271999835968018 -220.12600708007812,-105.47899627685547 C-220.36900329589844,-106.59400177001953 -218.96299743652344,-107.33000183105469 -218.09500122070312,-106.58799743652344 C-50.35300064086914,36.678001403808594 118.61499786376953,-13.772000312805176 153.87899780273438,-105.43900299072266z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,386.4539794921875,469.61798095703125)" opacity="1"
                    style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M57.97100067138672,-17.548999786376953 C68.51699829101562,19.506000518798828 51.090999603271484,57.404998779296875 19.08300018310547,67.10600280761719 C-12.946000099182129,76.78700256347656 -47.44599914550781,54.624000549316406 -57.97100067138672,17.56999969482422 C-68.51699829101562,-19.485000610351562 -51.11199951171875,-57.38399887084961 -19.083999633789062,-67.08599853515625 C12.925000190734863,-76.78700256347656 47.42499923706055,-54.60300064086914 57.97100067138672,-17.548999786376953z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,676.074951171875,469.61798095703125)" opacity="1"
                    style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M-57.97100067138672,-17.548999786376953 C-68.51699829101562,19.506000518798828 -51.090999603271484,57.404998779296875 -19.08300018310547,67.10600280761719 C12.946000099182129,76.78700256347656 47.44599914550781,54.624000549316406 57.97100067138672,17.56999969482422 C68.51699829101562,-19.485000610351562 51.112998962402344,-57.38399887084961 19.083999633789062,-67.08599853515625 C-12.923999786376953,-76.78700256347656 -47.42499923706055,-54.60300064086914 -57.97100067138672,-17.548999786376953z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,563.3378295898438,707.7218627929688)"
                    opacity="1" style="display: block;">
                    <g opacity="1"
                      transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                      <path fill="rgb(13,170,93)" fill-opacity="1"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                      <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                        stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,834.247802734375,707.7218627929688)"
                    opacity="1" style="display: block;">
                    <g opacity="1"
                      transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                      <path fill="rgb(13,170,93)" fill-opacity="1"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                      <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                        stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg><img src="../../../../assets/img/apuyen_ia.png" alt="Apuyen Logo" class="brand-image"
                style="width: 4em;height: 2em;"></div>
            <div title="Satisfacción del Expediente" v-if="this.satisfaccion == '5'"><svg
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                <defs>
                  <clipPath id="__lottie_element_2">
                    <rect width="1200" height="1300" x="0" y="0"></rect>
                  </clipPath>
                </defs>
                <g clip-path="url(#__lottie_element_2)">
                  <g transform="matrix(1,0,0,1,99.7659912109375,146.0260009765625)" opacity="1" style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,500.2340087890625,503.9739990234375)">
                      <path fill="rgb(13,170,93)" fill-opacity="1"
                        d=" M0,-499.989990234375 C141.947998046875,-499.989990234375 270.1050109863281,-440.80499267578125 361.12799072265625,-345.7720031738281 C447.1440124511719,-255.9669952392578 500,-134.1490020751953 500,-0.009999999776482582 C500,123.18599700927734 455.4230041503906,235.98599243164062 381.52301025390625,323.1419982910156 C289.7980041503906,431.32000732421875 152.89999389648438,499.989990234375 0,499.989990234375 C-148.9510040283203,499.989990234375 -282.7170104980469,434.8210144042969 -374.3269958496094,331.4429931640625 C-452.5190124511719,243.20700073242188 -500,127.13500213623047 -500,-0.009999999776482582 C-500,-138.05599975585938 -444.0190124511719,-263.0530090332031 -353.5270080566406,-353.5369873046875 C-263.0379943847656,-444.01800537109375 -138.0399932861328,-499.989990234375 0,-499.989990234375z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,402.0799560546875,703.9949951171875)" opacity="1"
                    style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,220.6199951171875,107.58100128173828)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M153.87899780273438,-105.43900299072266 C154.41000366210938,-106.81999969482422 156.17599487304688,-107.03700256347656 156.8300018310547,-105.70999908447266 C167.44700622558594,-84.16200256347656 220.36900329589844,38.513999938964844 70.15299987792969,76.79000091552734 C-49.08599853515625,107.33000183105469 -195.8470001220703,6.271999835968018 -220.12600708007812,-105.47899627685547 C-220.36900329589844,-106.59400177001953 -218.96299743652344,-107.33000183105469 -218.09500122070312,-106.58799743652344 C-50.35300064086914,36.678001403808594 118.61499786376953,-13.772000312805176 153.87899780273438,-105.43900299072266z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,386.4539794921875,469.61798095703125)" opacity="1"
                    style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M57.97100067138672,-17.548999786376953 C68.51699829101562,19.506000518798828 51.090999603271484,57.404998779296875 19.08300018310547,67.10600280761719 C-12.946000099182129,76.78700256347656 -47.44599914550781,54.624000549316406 -57.97100067138672,17.56999969482422 C-68.51699829101562,-19.485000610351562 -51.11199951171875,-57.38399887084961 -19.083999633789062,-67.08599853515625 C12.925000190734863,-76.78700256347656 47.42499923706055,-54.60300064086914 57.97100067138672,-17.548999786376953z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,676.074951171875,469.61798095703125)" opacity="1"
                    style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M-57.97100067138672,-17.548999786376953 C-68.51699829101562,19.506000518798828 -51.090999603271484,57.404998779296875 -19.08300018310547,67.10600280761719 C12.946000099182129,76.78700256347656 47.44599914550781,54.624000549316406 57.97100067138672,17.56999969482422 C68.51699829101562,-19.485000610351562 51.112998962402344,-57.38399887084961 19.083999633789062,-67.08599853515625 C-12.923999786376953,-76.78700256347656 -47.42499923706055,-54.60300064086914 -57.97100067138672,-17.548999786376953z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,563.3378295898438,707.7218627929688)"
                    opacity="1" style="display: block;">
                    <g opacity="1"
                      transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                      <path fill="rgb(13,170,93)" fill-opacity="1"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                      <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                        stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,834.247802734375,707.7218627929688)"
                    opacity="1" style="display: block;">
                    <g opacity="1"
                      transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                      <path fill="rgb(13,170,93)" fill-opacity="1"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                      <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                        stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg><img src="../../../../assets/img/apuyen_ia.png" alt="Apuyen Logo" class="brand-image"
                style="width: 4em;height: 2em;"></div>
            <div title="Satisfacción del Expediente" v-if="this.satisfaccion == '3'"><svg
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                <defs>
                  <clipPath id="__lottie_element_22">
                    <rect width="1200" height="1300" x="0" y="0"></rect>
                  </clipPath>
                </defs>
                <g clip-path="url(#__lottie_element_22)">
                  <g transform="matrix(1,0,0,1,99.739990234375,149.75)" opacity="1" style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,500.260009765625,500.25)">
                      <path fill="rgb(153,205,155)" fill-opacity="1"
                        d=" M0,-500 C134.2270050048828,-500 256.12298583984375,-447.0799865722656 345.95098876953125,-360.9700012207031 C440.8909912109375,-269.9599914550781 500.010009765625,-141.87600708007812 500.010009765625,-0.009999999776482582 C500.010009765625,138.86500549316406 443.364990234375,264.5320129394531 351.9259948730469,355.1440124511719 C261.56500244140625,444.68798828125 137.2270050048828,500 0,500 C-136.88299560546875,500 -260.9410095214844,444.96600341796875 -351.2449951171875,355.8179931640625 C-443.0840148925781,265.1549987792969 -500.010009765625,139.2100067138672 -500.010009765625,-0.009999999776482582 C-500.010009765625,-140.22500610351562 -442.2590026855469,-266.97698974609375 -349.2510070800781,-357.77801513671875 C-259.1189880371094,-445.77099609375 -135.8780059814453,-500 0,-500z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,386.50299072265625,473.343017578125)" opacity="1"
                    style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,68.76799774169922,77.03900146484375)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M57.972999572753906,-17.56999969482422 C68.51899719238281,19.485000610351562 51.09299850463867,57.38600158691406 19.062999725341797,67.08799743652344 C-12.944999694824219,76.78900146484375 -47.44599914550781,54.604000091552734 -57.992000579833984,17.548999786376953 C-68.51799774169922,-19.506999969482422 -51.112998962402344,-57.404998779296875 -19.083999633789062,-67.10700225830078 C12.925999641418457,-76.78800201416016 47.426998138427734,-54.625999450683594 57.972999572753906,-17.56999969482422z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,676.1220092773438,473.343017578125)" opacity="1" style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,68.76799774169922,77.03900146484375)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M-57.97200012207031,-17.56999969482422 C-68.51799774169922,19.485000610351562 -51.09199905395508,57.38600158691406 -19.062999725341797,67.08799743652344 C12.946000099182129,76.78900146484375 47.446998596191406,54.604000091552734 57.99399948120117,17.548999786376953 C68.51899719238281,-19.506999969482422 51.112998962402344,-57.404998779296875 19.083999633789062,-67.10700225830078 C-12.925000190734863,-76.78800201416016 -47.42599868774414,-54.625999450683594 -57.97200012207031,-17.56999969482422z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(0.909089982509613,0,0,0.909089982509613,600.001708984375,714.9097290039062)"
                    opacity="1" style="display: block;">
                    <g opacity="1"
                      transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                      <path fill="rgb(153,205,155)" fill-opacity="1"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                      <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                        stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(0.909089982509613,0,0,0.909089982509613,870.9087524414062,714.9097290039062)"
                    opacity="1" style="display: block;">
                    <g opacity="1"
                      transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                      <path fill="rgb(153,205,155)" fill-opacity="1"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                      <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                        stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,404.7409973144531,755.72998046875)" opacity="1" style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,194.54200744628906,47.52399826049805)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M-191.01400756835938,-46.43899917602539 C-192.64599609375,-47.27399826049805 -194.29200744628906,-45.32500076293945 -193.1699981689453,-43.875 C-157.46600341796875,2.246999979019165 -84.44200134277344,47.27299880981445 0.027000000700354576,47.27299880981445 C84.41999816894531,47.27299880981445 157.41799926757812,2.309000015258789 193.14999389648438,-43.779998779296875 C194.29200744628906,-45.250999450683594 192.6199951171875,-47.231998443603516 190.96200561523438,-46.382999420166016 C141.80499267578125,-21.256000518798828 74.4260025024414,0.9279999732971191 0.027000000700354576,0.9279999732971191 C-74.42500305175781,0.9279999732971191 -141.8489990234375,-21.290000915527344 -191.01400756835938,-46.43899917602539z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg><img src="../../../../assets/img/apuyen_ia.png" alt="Apuyen Logo" class="brand-image"
                style="width: 4em;height: 2em;"></div>
            <div title="Satisfacción del Expediente" v-if="this.satisfaccion == '2'"><svg
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                <defs>
                  <clipPath id="__lottie_element_42">
                    <rect width="1200" height="1300" x="0" y="0"></rect>
                  </clipPath>
                </defs>
                <g clip-path="url(#__lottie_element_42)">
                  <g transform="matrix(1,0,0,1,99.9949951171875,150.0050048828125)" opacity="1" style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,500.0050048828125,499.9949951171875)">
                      <path fill="rgb(239,158,161)" fill-opacity="1"
                        d=" M0,-499.7449951171875 C146.26400756835938,-499.7449951171875 277.87701416015625,-436.8760070800781 369.2799987792969,-336.6929931640625 C450.3240051269531,-247.86300659179688 499.7550048828125,-129.697998046875 499.7550048828125,-0.009999999776482582 C499.7550048828125,137.9759979248047 443.8039855957031,262.91400146484375 353.3590087890625,353.35699462890625 C262.91400146484375,443.79901123046875 137.9759979248047,499.7449951171875 0,499.7449951171875 C-145.9739990234375,499.7449951171875 -277.3550109863281,437.125 -368.7359924316406,337.2850036621094 C-450.1029968261719,248.38600158691406 -499.7550048828125,129.9770050048828 -499.7550048828125,-0.009999999776482582 C-499.7550048828125,-142.08700561523438 -440.4289855957031,-270.3349914550781 -345.2040100097656,-361.3320007324219 C-255.4759979248047,-447.07598876953125 -133.875,-499.7449951171875 0,-499.7449951171875z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,404.875,753.0499877929688)" opacity="1" style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,194.4429931640625,47.499000549316406)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M-0.02800000086426735,-47.249000549316406 C-84.3759994506836,-47.249000549316406 -157.33799743652344,-2.308000087738037 -193.052001953125,43.775001525878906 C-194.1929931640625,45.24700164794922 -192.52200317382812,47.22600173950195 -190.86399841308594,46.37799835205078 C-141.73399353027344,21.246000289916992 -74.38800048828125,-0.9279999732971191 -0.02800000086426735,-0.9279999732971191 C74.38600158691406,-0.9279999732971191 141.7760009765625,21.277999877929688 190.9149932861328,46.415000915527344 C192.5469970703125,47.249000549316406 194.1929931640625,45.301998138427734 193.0709991455078,43.85300064086914 C157.38499450683594,-2.244999885559082 84.39900207519531,-47.249000549316406 -0.02800000086426735,-47.249000549316406z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,386.5929870605469,473.41400146484375)" opacity="1"
                    style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,68.73300170898438,76.98899841308594)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M57.94300079345703,17.551000595092773 C68.48400115966797,-19.486000061035156 51.06700134277344,-57.36600112915039 19.073999404907227,-67.06300354003906 C-12.939000129699707,-76.73899841308594 -47.42300033569336,-54.58700180053711 -57.9630012512207,-17.549999237060547 C-68.48300170898438,19.486000061035156 -51.08700180053711,57.36600112915039 -19.073999404907227,67.06300354003906 C12.918999671936035,76.73899841308594 47.402000427246094,54.58700180053711 57.94300079345703,17.551000595092773z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,676.06396484375,473.41400146484375)" opacity="1" style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,68.73400115966797,76.98899841308594)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M-57.94200134277344,17.551000595092773 C-68.48300170898438,-19.486000061035156 -51.06700134277344,-57.36600112915039 -19.05299949645996,-67.06300354003906 C12.939000129699707,-76.73899841308594 47.422000885009766,-54.58700180053711 57.9630012512207,-17.549999237060547 C68.48300170898438,19.486000061035156 51.08700180053711,57.36600112915039 19.073999404907227,67.06300354003906 C-12.918999671936035,76.73899841308594 -47.4010009765625,54.58700180053711 -57.94200134277344,17.551000595092773z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,596.8357543945312,436.15179443359375)"
                    opacity="1" style="display: block;">
                    <g opacity="1"
                      transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                      <path fill="rgb(239,158,161)" fill-opacity="1"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                      <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                        stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,827.7437133789062,436.15179443359375)"
                    opacity="1" style="display: block;">
                    <g opacity="1"
                      transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                      <path fill="rgb(239,158,161)" fill-opacity="1"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                      <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                        stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg><img src="../../../../assets/img/apuyen_ia.png" alt="Apuyen Logo" class="brand-image"
                style="width: 4em;height: 2em;"></div>
            <div title="Satisfacción del Expediente" v-if="this.satisfaccion == '1'"><svg
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 1300"
                width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                <defs>
                  <clipPath id="__lottie_element_62">
                    <rect width="1200" height="1300" x="0" y="0"></rect>
                  </clipPath>
                </defs>
                <g clip-path="url(#__lottie_element_62)">
                  <g transform="matrix(1,0,0,1,100.0050048828125,150.0050048828125)" opacity="1"
                    style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,0.996999979019165,499.9949951171875,499.9949951171875)">
                      <path fill="rgb(226,78,103)" fill-opacity="1"
                        d=" M0.009999999776482582,-499.7449951171875 C136.44200134277344,-499.7449951171875 260.1210021972656,-445.0450134277344 350.30999755859375,-356.3840026855469 C442.5400085449219,-265.7149963378906 499.7449951171875,-139.531005859375 499.7449951171875,-0.009999999776482582 C499.7449951171875,136.48800659179688 444.99798583984375,260.2200012207031 356.2720031738281,350.4200134277344 C265.61199951171875,442.58599853515625 139.47300720214844,499.7449951171875 0.009999999776482582,499.7449951171875 C-149.1580047607422,499.7449951171875 -283.0889892578125,434.35400390625 -374.6679992675781,330.6759948730469 C-452.5039978027344,242.55799865722656 -499.7449951171875,126.78099822998047 -499.7449951171875,-0.009999999776482582 C-499.7449951171875,-128.78599548339844 -451.0060119628906,-246.2010040283203 -370.9739990234375,-334.8139953613281 C-279.5069885253906,-436.0889892578125 -147.16600036621094,-499.7449951171875 0.009999999776482582,-499.7449951171875z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,402.7510070800781,681.197998046875)" opacity="1" style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,220.28799438476562,107.28700256347656)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M153.7530059814453,105.4010009765625 C154.29600524902344,106.81600189208984 156.10800170898438,107.03600311279297 156.77699279785156,105.6760025024414 C167.49000549316406,83.89299774169922 220.03799438476562,-38.46200180053711 70.0999984741211,-76.89299774169922 C-48.470001220703125,-107.03700256347656 -194.26400756835938,-7.196000099182129 -219.65699768066406,103.81700134277344 C-220.03799438476562,105.48200225830078 -217.9499969482422,106.59200286865234 -216.64700317382812,105.48799896240234 C-49.48699951171875,-36.2400016784668 118.53099822998047,13.829999923706055 153.7530059814453,105.4010009765625z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,386.60699462890625,473.41900634765625)" opacity="1"
                    style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,68.73300170898438,77.01000213623047)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M57.94300079345703,17.549999237060547 C68.48400115966797,-19.486000061035156 51.06700134277344,-57.36600112915039 19.05299949645996,-67.06199645996094 C-12.939000129699707,-76.75900268554688 -47.42300033569336,-54.58599853515625 -57.9630012512207,-17.549999237060547 C-68.48300170898438,19.48699951171875 -51.08700180053711,57.36600112915039 -19.073999404907227,67.06300354003906 C12.918999671936035,76.75900268554688 47.402000427246094,54.58700180053711 57.94300079345703,17.549999237060547z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,676.0809936523438,473.41302490234375)" opacity="1"
                    style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,69.39299774169922,76.98899841308594)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M-57.94200134277344,17.551000595092773 C-68.48300170898438,-19.486000061035156 -51.06700134277344,-57.36600112915039 -19.05299949645996,-67.06300354003906 C12.939000129699707,-76.73899841308594 47.422000885009766,-54.58700180053711 57.9630012512207,-17.549999237060547 C68.48300170898438,19.486000061035156 51.08700180053711,57.36600112915039 19.073999404907227,67.06300354003906 C-12.918999671936035,76.73899841308594 -47.4010009765625,54.58700180053711 -57.94200134277344,17.551000595092773z">
                      </path>
                    </g>
                    <g opacity="1" transform="matrix(1,0,0,1,68.73300170898438,77.01399993896484)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M-57.94200134277344,17.549999237060547 C-68.48300170898438,-19.486000061035156 -51.066001892089844,-57.36600112915039 -19.073999404907227,-67.06199645996094 C12.939000129699707,-76.75900268554688 47.422000885009766,-54.58599853515625 57.96200180053711,-17.549999237060547 C68.48300170898438,19.48699951171875 51.08599853515625,57.36600112915039 19.073999404907227,67.06300354003906 C-12.9399995803833,76.75900268554688 -47.402000427246094,54.58700180053711 -57.94200134277344,17.549999237060547z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,827.7437744140625,436.1528015136719)"
                    opacity="1" style="display: block;">
                    <g opacity="1"
                      transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                      <path fill="rgb(226,78,103)" fill-opacity="1"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                      <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                        stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,596.834716796875,436.1528015136719)"
                    opacity="1" style="display: block;">
                    <g opacity="1"
                      transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                      <path fill="rgb(226,78,103)" fill-opacity="1"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                      <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                        stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg><img src="../../../../assets/img/apuyen_ia.png" alt="Apuyen Logo" class="brand-image"
                style="width: 4em;height: 2em;"></div>
            <div title="Satisfacción del Expediente" v-if="this.satisfaccion == '0'"><svg viewBox="0 0 1200 1300"
                style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;"
                xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <clippath id="__lottie_element_2">
                    <rect width="1200" height="1300" x="0" y="0"></rect>
                  </clippath>
                </defs>
                <g clip-path="url(#__lottie_element_2)">
                  <g transform="matrix(1,0,0,1,99.7659912109375,146.0260009765625)" opacity="1" style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,500.2340087890625,503.9739990234375)">
                      <path fill-opacity="1"
                        d=" M0,-499.989990234375 C141.947998046875,-499.989990234375 270.1050109863281,-440.80499267578125 361.12799072265625,-345.7720031738281 C447.1440124511719,-255.9669952392578 500,-134.1490020751953 500,-0.009999999776482582 C500,123.18599700927734 455.4230041503906,235.98599243164062 381.52301025390625,323.1419982910156 C289.7980041503906,431.32000732421875 152.89999389648438,499.989990234375 0,499.989990234375 C-148.9510040283203,499.989990234375 -282.7170104980469,434.8210144042969 -374.3269958496094,331.4429931640625 C-452.5190124511719,243.20700073242188 -500,127.13500213623047 -500,-0.009999999776482582 C-500,-138.05599975585938 -444.0190124511719,-263.0530090332031 -353.5270080566406,-353.5369873046875 C-263.0379943847656,-444.01800537109375 -138.0399932861328,-499.989990234375 0,-499.989990234375z"
                        style="fill: rgb(108, 117, 125);"></path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,386.4539794921875,469.61798095703125)" opacity="1"
                    style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M57.97100067138672,-17.548999786376953 C68.51699829101562,19.506000518798828 51.090999603271484,57.404998779296875 19.08300018310547,67.10600280761719 C-12.946000099182129,76.78700256347656 -47.44599914550781,54.624000549316406 -57.97100067138672,17.56999969482422 C-68.51699829101562,-19.485000610351562 -51.11199951171875,-57.38399887084961 -19.083999633789062,-67.08599853515625 C12.925000190734863,-76.78700256347656 47.42499923706055,-54.60300064086914 57.97100067138672,-17.548999786376953z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,676.074951171875,469.61798095703125)" opacity="1"
                    style="display: block;">
                    <g opacity="1" transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                      <path fill="rgb(0,0,0)" fill-opacity="1"
                        d=" M-57.97100067138672,-17.548999786376953 C-68.51699829101562,19.506000518798828 -51.090999603271484,57.404998779296875 -19.08300018310547,67.10600280761719 C12.946000099182129,76.78700256347656 47.44599914550781,54.624000549316406 57.97100067138672,17.56999969482422 C68.51699829101562,-19.485000610351562 51.112998962402344,-57.38399887084961 19.083999633789062,-67.08599853515625 C-12.923999786376953,-76.78700256347656 -47.42499923706055,-54.60300064086914 -57.97100067138672,-17.548999786376953z">
                      </path>
                    </g>
                  </g>
                  <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,563.3378295898438,707.7218627929688)"
                    opacity="1" style="display: block;">
                    <g opacity="1"
                      transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                      <path fill-opacity="1"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                        style="fill: rgb(108, 117, 125);"></path>
                      <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                        stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                        style="fill: rgb(108, 117, 125);"></path>
                    </g>
                  </g>
                  <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,834.247802734375,707.7218627929688)"
                    opacity="1" style="display: block;">
                    <g opacity="1"
                      transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                      <path fill-opacity="1"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                        style="fill: rgb(108, 117, 125);"></path>
                      <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                        stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                        d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                        style="fill: rgb(108, 117, 125);"></path>
                    </g>
                  </g>
                  <path style="stroke: rgb(0, 0, 0);"
                    d="M 339.265 751.008 L 341.142 792.645 L 857.415 791.74 L 857.415 751.008 L 339.265 751.008 Z">
                  </path>
                </g>
              </svg><img src="../../../../assets/img/apuyen_ia.png" alt="Apuyen Logo" class="brand-image"
                style="width: 4em;height: 2em;"></div>
            <div v-if="isDialogVisible" class="dialogserv">
              <div><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 1200 1300" width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                  style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                  <defs>
                    <clipPath id="__lottie_element_2">
                      <rect width="1200" height="1300" x="0" y="0"></rect>
                    </clipPath>
                  </defs>
                  <g clip-path="url(#__lottie_element_2)">
                    <g transform="matrix(1,0,0,1,99.7659912109375,146.0260009765625)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,500.2340087890625,503.9739990234375)">
                        <path fill="rgb(13,170,93)" fill-opacity="1"
                          d=" M0,-499.989990234375 C141.947998046875,-499.989990234375 270.1050109863281,-440.80499267578125 361.12799072265625,-345.7720031738281 C447.1440124511719,-255.9669952392578 500,-134.1490020751953 500,-0.009999999776482582 C500,123.18599700927734 455.4230041503906,235.98599243164062 381.52301025390625,323.1419982910156 C289.7980041503906,431.32000732421875 152.89999389648438,499.989990234375 0,499.989990234375 C-148.9510040283203,499.989990234375 -282.7170104980469,434.8210144042969 -374.3269958496094,331.4429931640625 C-452.5190124511719,243.20700073242188 -500,127.13500213623047 -500,-0.009999999776482582 C-500,-138.05599975585938 -444.0190124511719,-263.0530090332031 -353.5270080566406,-353.5369873046875 C-263.0379943847656,-444.01800537109375 -138.0399932861328,-499.989990234375 0,-499.989990234375z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,402.0799560546875,703.9949951171875)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,220.6199951171875,107.58100128173828)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M153.87899780273438,-105.43900299072266 C154.41000366210938,-106.81999969482422 156.17599487304688,-107.03700256347656 156.8300018310547,-105.70999908447266 C167.44700622558594,-84.16200256347656 220.36900329589844,38.513999938964844 70.15299987792969,76.79000091552734 C-49.08599853515625,107.33000183105469 -195.8470001220703,6.271999835968018 -220.12600708007812,-105.47899627685547 C-220.36900329589844,-106.59400177001953 -218.96299743652344,-107.33000183105469 -218.09500122070312,-106.58799743652344 C-50.35300064086914,36.678001403808594 118.61499786376953,-13.772000312805176 153.87899780273438,-105.43900299072266z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,386.4539794921875,469.61798095703125)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M57.97100067138672,-17.548999786376953 C68.51699829101562,19.506000518798828 51.090999603271484,57.404998779296875 19.08300018310547,67.10600280761719 C-12.946000099182129,76.78700256347656 -47.44599914550781,54.624000549316406 -57.97100067138672,17.56999969482422 C-68.51699829101562,-19.485000610351562 -51.11199951171875,-57.38399887084961 -19.083999633789062,-67.08599853515625 C12.925000190734863,-76.78700256347656 47.42499923706055,-54.60300064086914 57.97100067138672,-17.548999786376953z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,676.074951171875,469.61798095703125)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M-57.97100067138672,-17.548999786376953 C-68.51699829101562,19.506000518798828 -51.090999603271484,57.404998779296875 -19.08300018310547,67.10600280761719 C12.946000099182129,76.78700256347656 47.44599914550781,54.624000549316406 57.97100067138672,17.56999969482422 C68.51699829101562,-19.485000610351562 51.112998962402344,-57.38399887084961 19.083999633789062,-67.08599853515625 C-12.923999786376953,-76.78700256347656 -47.42499923706055,-54.60300064086914 -57.97100067138672,-17.548999786376953z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,563.3378295898438,707.7218627929688)"
                      opacity="1" style="display: block;">
                      <g opacity="1"
                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                        <path fill="rgb(13,170,93)" fill-opacity="1"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                        <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                          stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,834.247802734375,707.7218627929688)"
                      opacity="1" style="display: block;">
                      <g opacity="1"
                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                        <path fill="rgb(13,170,93)" fill-opacity="1"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                        <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                          stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                      </g>
                    </g>
                  </g>
                </svg><span>Muy satisfecho</span></div>
              <div><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 1200 1300" width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                  style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                  <defs>
                    <clipPath id="__lottie_element_22">
                      <rect width="1200" height="1300" x="0" y="0"></rect>
                    </clipPath>
                  </defs>
                  <g clip-path="url(#__lottie_element_22)">
                    <g transform="matrix(1,0,0,1,99.739990234375,149.75)" opacity="1" style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,500.260009765625,500.25)">
                        <path fill="rgb(153,205,155)" fill-opacity="1"
                          d=" M0,-500 C134.2270050048828,-500 256.12298583984375,-447.0799865722656 345.95098876953125,-360.9700012207031 C440.8909912109375,-269.9599914550781 500.010009765625,-141.87600708007812 500.010009765625,-0.009999999776482582 C500.010009765625,138.86500549316406 443.364990234375,264.5320129394531 351.9259948730469,355.1440124511719 C261.56500244140625,444.68798828125 137.2270050048828,500 0,500 C-136.88299560546875,500 -260.9410095214844,444.96600341796875 -351.2449951171875,355.8179931640625 C-443.0840148925781,265.1549987792969 -500.010009765625,139.2100067138672 -500.010009765625,-0.009999999776482582 C-500.010009765625,-140.22500610351562 -442.2590026855469,-266.97698974609375 -349.2510070800781,-357.77801513671875 C-259.1189880371094,-445.77099609375 -135.8780059814453,-500 0,-500z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,386.50299072265625,473.343017578125)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,68.76799774169922,77.03900146484375)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M57.972999572753906,-17.56999969482422 C68.51899719238281,19.485000610351562 51.09299850463867,57.38600158691406 19.062999725341797,67.08799743652344 C-12.944999694824219,76.78900146484375 -47.44599914550781,54.604000091552734 -57.992000579833984,17.548999786376953 C-68.51799774169922,-19.506999969482422 -51.112998962402344,-57.404998779296875 -19.083999633789062,-67.10700225830078 C12.925999641418457,-76.78800201416016 47.426998138427734,-54.625999450683594 57.972999572753906,-17.56999969482422z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,676.1220092773438,473.343017578125)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,68.76799774169922,77.03900146484375)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M-57.97200012207031,-17.56999969482422 C-68.51799774169922,19.485000610351562 -51.09199905395508,57.38600158691406 -19.062999725341797,67.08799743652344 C12.946000099182129,76.78900146484375 47.446998596191406,54.604000091552734 57.99399948120117,17.548999786376953 C68.51899719238281,-19.506999969482422 51.112998962402344,-57.404998779296875 19.083999633789062,-67.10700225830078 C-12.925000190734863,-76.78800201416016 -47.42599868774414,-54.625999450683594 -57.97200012207031,-17.56999969482422z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(0.909089982509613,0,0,0.909089982509613,600.001708984375,714.9097290039062)"
                      opacity="1" style="display: block;">
                      <g opacity="1"
                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                        <path fill="rgb(153,205,155)" fill-opacity="1"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                        <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                          stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(0.909089982509613,0,0,0.909089982509613,870.9087524414062,714.9097290039062)"
                      opacity="1" style="display: block;">
                      <g opacity="1"
                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                        <path fill="rgb(153,205,155)" fill-opacity="1"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                        <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                          stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,404.7409973144531,755.72998046875)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,194.54200744628906,47.52399826049805)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M-191.01400756835938,-46.43899917602539 C-192.64599609375,-47.27399826049805 -194.29200744628906,-45.32500076293945 -193.1699981689453,-43.875 C-157.46600341796875,2.246999979019165 -84.44200134277344,47.27299880981445 0.027000000700354576,47.27299880981445 C84.41999816894531,47.27299880981445 157.41799926757812,2.309000015258789 193.14999389648438,-43.779998779296875 C194.29200744628906,-45.250999450683594 192.6199951171875,-47.231998443603516 190.96200561523438,-46.382999420166016 C141.80499267578125,-21.256000518798828 74.4260025024414,0.9279999732971191 0.027000000700354576,0.9279999732971191 C-74.42500305175781,0.9279999732971191 -141.8489990234375,-21.290000915527344 -191.01400756835938,-46.43899917602539z">
                        </path>
                      </g>
                    </g>
                  </g>
                </svg><span>Satisfecho</span></div>
              <div><svg viewBox="0 0 1200 1300"
                  style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;"
                  xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <clippath id="__lottie_element_2">
                      <rect width="1200" height="1300" x="0" y="0"></rect>
                    </clippath>
                  </defs>
                  <g clip-path="url(#__lottie_element_2)">
                    <g transform="matrix(1,0,0,1,99.7659912109375,146.0260009765625)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,500.2340087890625,503.9739990234375)">
                        <path fill-opacity="1"
                          d=" M0,-499.989990234375 C141.947998046875,-499.989990234375 270.1050109863281,-440.80499267578125 361.12799072265625,-345.7720031738281 C447.1440124511719,-255.9669952392578 500,-134.1490020751953 500,-0.009999999776482582 C500,123.18599700927734 455.4230041503906,235.98599243164062 381.52301025390625,323.1419982910156 C289.7980041503906,431.32000732421875 152.89999389648438,499.989990234375 0,499.989990234375 C-148.9510040283203,499.989990234375 -282.7170104980469,434.8210144042969 -374.3269958496094,331.4429931640625 C-452.5190124511719,243.20700073242188 -500,127.13500213623047 -500,-0.009999999776482582 C-500,-138.05599975585938 -444.0190124511719,-263.0530090332031 -353.5270080566406,-353.5369873046875 C-263.0379943847656,-444.01800537109375 -138.0399932861328,-499.989990234375 0,-499.989990234375z"
                          style="fill: rgb(108, 117, 125);"></path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,386.4539794921875,469.61798095703125)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M57.97100067138672,-17.548999786376953 C68.51699829101562,19.506000518798828 51.090999603271484,57.404998779296875 19.08300018310547,67.10600280761719 C-12.946000099182129,76.78700256347656 -47.44599914550781,54.624000549316406 -57.97100067138672,17.56999969482422 C-68.51699829101562,-19.485000610351562 -51.11199951171875,-57.38399887084961 -19.083999633789062,-67.08599853515625 C12.925000190734863,-76.78700256347656 47.42499923706055,-54.60300064086914 57.97100067138672,-17.548999786376953z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,676.074951171875,469.61798095703125)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,68.76699829101562,77.03700256347656)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M-57.97100067138672,-17.548999786376953 C-68.51699829101562,19.506000518798828 -51.090999603271484,57.404998779296875 -19.08300018310547,67.10600280761719 C12.946000099182129,76.78700256347656 47.44599914550781,54.624000549316406 57.97100067138672,17.56999969482422 C68.51699829101562,-19.485000610351562 51.112998962402344,-57.38399887084961 19.083999633789062,-67.08599853515625 C-12.923999786376953,-76.78700256347656 -47.42499923706055,-54.60300064086914 -57.97100067138672,-17.548999786376953z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,563.3378295898438,707.7218627929688)"
                      opacity="1" style="display: block;">
                      <g opacity="1"
                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                        <path fill-opacity="1"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                          style="fill: rgb(108, 117, 125);"></path>
                        <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                          stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                          style="fill: rgb(108, 117, 125);"></path>
                      </g>
                    </g>
                    <g transform="matrix(0.6690899729728699,0,0,0.6690899729728699,834.247802734375,707.7218627929688)"
                      opacity="1" style="display: block;">
                      <g opacity="1"
                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                        <path fill-opacity="1"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                          style="fill: rgb(108, 117, 125);"></path>
                        <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                          stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z"
                          style="fill: rgb(108, 117, 125);"></path>
                      </g>
                    </g>
                    <path style="stroke: rgb(0, 0, 0);"
                      d="M 339.265 751.008 L 341.142 792.645 L 857.415 791.74 L 857.415 751.008 L 339.265 751.008 Z">
                    </path>
                  </g>
                </svg><span>Neutral</span></div>
              <div><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 1200 1300" width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                  style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                  <defs>
                    <clipPath id="__lottie_element_42">
                      <rect width="1200" height="1300" x="0" y="0"></rect>
                    </clipPath>
                  </defs>
                  <g clip-path="url(#__lottie_element_42)">
                    <g transform="matrix(1,0,0,1,99.9949951171875,150.0050048828125)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,500.0050048828125,499.9949951171875)">
                        <path fill="rgb(239,158,161)" fill-opacity="1"
                          d=" M0,-499.7449951171875 C146.26400756835938,-499.7449951171875 277.87701416015625,-436.8760070800781 369.2799987792969,-336.6929931640625 C450.3240051269531,-247.86300659179688 499.7550048828125,-129.697998046875 499.7550048828125,-0.009999999776482582 C499.7550048828125,137.9759979248047 443.8039855957031,262.91400146484375 353.3590087890625,353.35699462890625 C262.91400146484375,443.79901123046875 137.9759979248047,499.7449951171875 0,499.7449951171875 C-145.9739990234375,499.7449951171875 -277.3550109863281,437.125 -368.7359924316406,337.2850036621094 C-450.1029968261719,248.38600158691406 -499.7550048828125,129.9770050048828 -499.7550048828125,-0.009999999776482582 C-499.7550048828125,-142.08700561523438 -440.4289855957031,-270.3349914550781 -345.2040100097656,-361.3320007324219 C-255.4759979248047,-447.07598876953125 -133.875,-499.7449951171875 0,-499.7449951171875z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,404.875,753.0499877929688)" opacity="1" style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,194.4429931640625,47.499000549316406)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M-0.02800000086426735,-47.249000549316406 C-84.3759994506836,-47.249000549316406 -157.33799743652344,-2.308000087738037 -193.052001953125,43.775001525878906 C-194.1929931640625,45.24700164794922 -192.52200317382812,47.22600173950195 -190.86399841308594,46.37799835205078 C-141.73399353027344,21.246000289916992 -74.38800048828125,-0.9279999732971191 -0.02800000086426735,-0.9279999732971191 C74.38600158691406,-0.9279999732971191 141.7760009765625,21.277999877929688 190.9149932861328,46.415000915527344 C192.5469970703125,47.249000549316406 194.1929931640625,45.301998138427734 193.0709991455078,43.85300064086914 C157.38499450683594,-2.244999885559082 84.39900207519531,-47.249000549316406 -0.02800000086426735,-47.249000549316406z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,386.5929870605469,473.41400146484375)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,68.73300170898438,76.98899841308594)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M57.94300079345703,17.551000595092773 C68.48400115966797,-19.486000061035156 51.06700134277344,-57.36600112915039 19.073999404907227,-67.06300354003906 C-12.939000129699707,-76.73899841308594 -47.42300033569336,-54.58700180053711 -57.9630012512207,-17.549999237060547 C-68.48300170898438,19.486000061035156 -51.08700180053711,57.36600112915039 -19.073999404907227,67.06300354003906 C12.918999671936035,76.73899841308594 47.402000427246094,54.58700180053711 57.94300079345703,17.551000595092773z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,676.06396484375,473.41400146484375)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,68.73400115966797,76.98899841308594)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M-57.94200134277344,17.551000595092773 C-68.48300170898438,-19.486000061035156 -51.06700134277344,-57.36600112915039 -19.05299949645996,-67.06300354003906 C12.939000129699707,-76.73899841308594 47.422000885009766,-54.58700180053711 57.9630012512207,-17.549999237060547 C68.48300170898438,19.486000061035156 51.08700180053711,57.36600112915039 19.073999404907227,67.06300354003906 C-12.918999671936035,76.73899841308594 -47.4010009765625,54.58700180053711 -57.94200134277344,17.551000595092773z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,596.8357543945312,436.15179443359375)"
                      opacity="1" style="display: block;">
                      <g opacity="1"
                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                        <path fill="rgb(239,158,161)" fill-opacity="1"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                        <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                          stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,827.7437133789062,436.15179443359375)"
                      opacity="1" style="display: block;">
                      <g opacity="1"
                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                        <path fill="rgb(239,158,161)" fill-opacity="1"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                        <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                          stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                      </g>
                    </g>
                  </g>
                </svg><span>Poco satisfecho</span> </div>
              <div><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 1200 1300" width="1200" height="1300" preserveAspectRatio="xMidYMid meet"
                  style="width: 50px; height: 50px; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
                  <defs>
                    <clipPath id="__lottie_element_62">
                      <rect width="1200" height="1300" x="0" y="0"></rect>
                    </clipPath>
                  </defs>
                  <g clip-path="url(#__lottie_element_62)">
                    <g transform="matrix(1,0,0,1,100.0050048828125,150.0050048828125)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,0.996999979019165,499.9949951171875,499.9949951171875)">
                        <path fill="rgb(226,78,103)" fill-opacity="1"
                          d=" M0.009999999776482582,-499.7449951171875 C136.44200134277344,-499.7449951171875 260.1210021972656,-445.0450134277344 350.30999755859375,-356.3840026855469 C442.5400085449219,-265.7149963378906 499.7449951171875,-139.531005859375 499.7449951171875,-0.009999999776482582 C499.7449951171875,136.48800659179688 444.99798583984375,260.2200012207031 356.2720031738281,350.4200134277344 C265.61199951171875,442.58599853515625 139.47300720214844,499.7449951171875 0.009999999776482582,499.7449951171875 C-149.1580047607422,499.7449951171875 -283.0889892578125,434.35400390625 -374.6679992675781,330.6759948730469 C-452.5039978027344,242.55799865722656 -499.7449951171875,126.78099822998047 -499.7449951171875,-0.009999999776482582 C-499.7449951171875,-128.78599548339844 -451.0060119628906,-246.2010040283203 -370.9739990234375,-334.8139953613281 C-279.5069885253906,-436.0889892578125 -147.16600036621094,-499.7449951171875 0.009999999776482582,-499.7449951171875z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,402.7510070800781,681.197998046875)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,220.28799438476562,107.28700256347656)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M153.7530059814453,105.4010009765625 C154.29600524902344,106.81600189208984 156.10800170898438,107.03600311279297 156.77699279785156,105.6760025024414 C167.49000549316406,83.89299774169922 220.03799438476562,-38.46200180053711 70.0999984741211,-76.89299774169922 C-48.470001220703125,-107.03700256347656 -194.26400756835938,-7.196000099182129 -219.65699768066406,103.81700134277344 C-220.03799438476562,105.48200225830078 -217.9499969482422,106.59200286865234 -216.64700317382812,105.48799896240234 C-49.48699951171875,-36.2400016784668 118.53099822998047,13.829999923706055 153.7530059814453,105.4010009765625z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,386.60699462890625,473.41900634765625)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,68.73300170898438,77.01000213623047)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M57.94300079345703,17.549999237060547 C68.48400115966797,-19.486000061035156 51.06700134277344,-57.36600112915039 19.05299949645996,-67.06199645996094 C-12.939000129699707,-76.75900268554688 -47.42300033569336,-54.58599853515625 -57.9630012512207,-17.549999237060547 C-68.48300170898438,19.48699951171875 -51.08700180053711,57.36600112915039 -19.073999404907227,67.06300354003906 C12.918999671936035,76.75900268554688 47.402000427246094,54.58700180053711 57.94300079345703,17.549999237060547z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(1,0,0,1,676.0809936523438,473.41302490234375)" opacity="1"
                      style="display: block;">
                      <g opacity="1" transform="matrix(1,0,0,1,69.39299774169922,76.98899841308594)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M-57.94200134277344,17.551000595092773 C-68.48300170898438,-19.486000061035156 -51.06700134277344,-57.36600112915039 -19.05299949645996,-67.06300354003906 C12.939000129699707,-76.73899841308594 47.422000885009766,-54.58700180053711 57.9630012512207,-17.549999237060547 C68.48300170898438,19.486000061035156 51.08700180053711,57.36600112915039 19.073999404907227,67.06300354003906 C-12.918999671936035,76.73899841308594 -47.4010009765625,54.58700180053711 -57.94200134277344,17.551000595092773z">
                        </path>
                      </g>
                      <g opacity="1" transform="matrix(1,0,0,1,68.73300170898438,77.01399993896484)">
                        <path fill="rgb(0,0,0)" fill-opacity="1"
                          d=" M-57.94200134277344,17.549999237060547 C-68.48300170898438,-19.486000061035156 -51.066001892089844,-57.36600112915039 -19.073999404907227,-67.06199645996094 C12.939000129699707,-76.75900268554688 47.422000885009766,-54.58599853515625 57.96200180053711,-17.549999237060547 C68.48300170898438,19.48699951171875 51.08599853515625,57.36600112915039 19.073999404907227,67.06300354003906 C-12.9399995803833,76.75900268554688 -47.402000427246094,54.58700180053711 -57.94200134277344,17.549999237060547z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,827.7437744140625,436.1528015136719)"
                      opacity="1" style="display: block;">
                      <g opacity="1"
                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                        <path fill="rgb(226,78,103)" fill-opacity="1"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                        <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                          stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                      </g>
                    </g>
                    <g transform="matrix(0.7836300134658813,0,0,0.7836300134658813,596.834716796875,436.1528015136719)"
                      opacity="1" style="display: block;">
                      <g opacity="1"
                        transform="matrix(1.3722800016403198,0,0,1.3722800016403198,-152.75799560546875,-29.94499969482422)">
                        <path fill="rgb(226,78,103)" fill-opacity="1"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                        <path stroke-linecap="butt" stroke-linejoin="miter" fill-opacity="0" stroke-miterlimit="4"
                          stroke="rgb(33,33,33)" stroke-opacity="1" stroke-width="0"
                          d=" M0,-50.89849853515625 C28.09088134765625,-50.89849853515625 50.89849853515625,-28.09088134765625 50.89849853515625,0 C50.89849853515625,28.09088134765625 28.09088134765625,50.89849853515625 0,50.89849853515625 C-28.09088134765625,50.89849853515625 -50.89849853515625,28.09088134765625 -50.89849853515625,0 C-50.89849853515625,-28.09088134765625 -28.09088134765625,-50.89849853515625 0,-50.89849853515625z">
                        </path>
                      </g>
                    </g>
                  </g>
                </svg><span>Muy insatisfecho</span></div>
            </div>
            <Dialog dismissableMask :style="{ width: '25rem' }" header="Explicación satisfacción" modal
              v-model:visible="mostrarExplicacion">
              <p>{{ explicacionSatisfaccion }}</p>
            </Dialog>
          </div>
        </div>
      </div>
      <servicio-lateral-tramitador :id="id" :servicio="servicio" :tramitador="tramitador" :permisosusuario="permisosusuario"/>
      <servicio-lateral-perito :id="id" :servicio="servicio" :perito="perito" />
      <servicio-lateral-indicadores v-if="servicioext && tramitaraux==false" :estadospendientes="estadospendientes"
        :id="id" :servicioext="servicioext" :servicio="servicio"
        :fecha="fecha" />
        
      <servicio-lateral-tipos v-if="tiposServAct && tramitaraux==false" :id="id" />
    </div>
</template>
<script>  
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import serviciolateraltramitador from './lateral_tramitador.vue';
import serviciolateralperito from './lateral_perito.vue';

import serviciolateralindicadores from './lateral_indicadores.vue';
import serviciolateraltipos from './lateral_tipos_servicio.vue';
import { PwgsApi } from '../../../../services/PwgsApi';
export default ({
    props: ['id', 'servicio','perito','tramitador','tramitaraux', 'asegurado', 'fecha', 'servicioext', 'estilo','estadospendientes', 'permisosusuario'],
    setup() {     
    },
    components: {
      'servicio-lateral-tramitador': serviciolateraltramitador,
      'servicio-lateral-perito': serviciolateralperito,

        'servicio-lateral-indicadores': serviciolateralindicadores,
        'servicio-lateral-tipos': serviciolateraltipos,
        Dialog,
        ConfirmDialog,
    },
    methods:{
      confirmar1() {
            this.$confirm.require({
                message: '¿Seguro que quieres reasignar el servicio?',
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'Cancelar',
                acceptLabel: 'Aceptar',
                accept: () => {
                    this.reasignar();
                },
                reject: () => {
                    return;
                }
            });
        },
        async reasignar() {
           const api = new PwgsApi();
           await api.put('/servicios/'+this.id+'/reasignar');
           this.$toast.add({ severity: 'success', summary: 'Reasignado', detail: 'Servicio reasignado correctamente', life: 2000 });
        },
        async comprobar_tipos_act(){
            const api = new PwgsApi();
            const act = await api.get('parametros-pwgs/gestion_tipos');
            if(act.gestion_tipos == 1){
                this.tiposServAct = true;
            }
        },
        calcularKMS(){
            if(this.$props.servicio.distancia){
                this.kms = this.$props.servicio.distancia+' KM';
            }
            else{
                this.kms = '';
            }
        },
        confirmar2() {
            this.$confirm.require({
                message: '¿Seguro que quieres recuperar el servicio?',
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'Cancelar',
                acceptLabel: 'Aceptar',
                accept: () => {
                    this.recuperar();
                },
                reject: () => {
                    return;
                }
            });
        },
        async recuperar(){
            const api = new PwgsApi();
            await api.put('/servicios/'+this.id+'/recupera');
            this.$toast.add({ severity: 'success', summary: 'Recuperado', detail: 'Servicio recuperado correctamente', life: 2000 });
            window.location.reload();
        },
        async obtenerSatisfaccion() {
                const api = new PwgsApi();
                const ia = await api.get('parametros-pwgs/inteligencia_artificial');
                const sat = await api.get('parametros-pwgs/inteligencia_artificial_recopila_satisfaccion');
                if(ia['inteligencia_artificial']=='1' && sat["inteligencia_artificial_recopila_satisfaccion"]=='1' && this.servicioext.datos.valorado_satisfaccion_ia_servicio == '1'){                    
                    this.satisfaccion = this.servicioext.datos.satisfaccion_ia_servicio;
                    this.explicacionSatisfaccion = this.servicioext.datos.explicacion_satisfaccion_ia_servicio;
                    console.log("satisfaccions",this.satisfaccion);
                }else{
                    this.satisfaccion = -1;
                    console.log("no satisfaccion");
                }
            },
            showDialog() {
      this.isDialogVisible = true;
    },
    hideDialog() {
      this.isDialogVisible = false;
    },
    comprobarFecha(){
        var fechas = this.servicioext.datos.fecha;
        console.log("fechas",fechas);
        var [ano, mes, dia] = fechas.split('-');
        this.fecha1 = dia.toString()+'-'+mes.toString()+'-'+ano.toString();
      },
      comprobarurgente() {
        console.log('servext', this.servicioext);
        if(this.servicioext.datos){
          if (this.$props.servicioext.datos.urgente_servicio == '1') {
            this.miestilo = "background:#dc3545 !important";
            this.urgente = true;
          }
          else {
            this.urgente = false; 
            this.miestilo = "background:#007bff !important";
            
          }
        }
      },
      comprobarconflictivo() {
        if(this.servicio.asegurado){
          console.log('srvc',this.servicio);
          if (this.$props.servicio.asegurado.conflictivo == '1') {
            this.conflictivo = true;
            console.log('esconf')
          }else{
            this.conflictivo = false;
            console.log('noconf')
          }
        }
      },
    comprobarReasignado(){
        if(this.servicio.estado=='Eliminado'){
         this.eliminado = true; 
        }
        if(this.servicio.reasignado=='1'){
            this.reasignado = true;
        }else{
            this.reasignado = false;
        }
        console.log("servicio",this.servicio);
        if(this.servicio.estado=='Finalizado'){
            console.log("estado2",this.servicio.estado);
            this.finalizado = true;
        }else{
            this.finalizado = false;
        }
    }
    },
    data() {
        return {
            explicacionSatisfaccion: "",
            mostrarExplicacion: false,
            satisfaccion: "",
            kms:"",
            tiposServAct: false,
            isDialogVisible: false,
            eliminado:false,
            mostrarEstado: false,
            reasignado: false,
            finalizado:false,
            fecha1:'',
            mostrarfecha:false,
          miestilo: 'background:#007bff !important',
          urgente: false,
          conflictivo: false,
        }
    },
    watch:{
        id(){
            this.obtenerSatisfaccion(this.$props.id);
        },
        servicioext(){
          this.comprobarFecha();
          this.comprobarurgente();           
        },
        servicio(){
            this.calcularKMS();
            this.comprobarReasignado();
            this.mostrarEstado = true;  
          this.mostrarfecha = true; 
          this.comprobarconflictivo();    
   
      },
      urgente(){
        this.comprobarconflictivo();
      }       
    },
    mounted() {
        this.comprobar_tipos_act();
        this.comprobarReasignado();
        this.obtenerSatisfaccion();
    },    
})


</script>
<style>
.satisf {
  position: relative;
  display: inline-block;
}

.dialogserv {
  position: absolute;
  bottom: 95%;
  left: 82%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 21;
}

.ribbon {
  width: 74px;
  height: 65px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 100px;
  padding: 5px 0;
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}

/* top left*/
.ribbon-top-left {
  top: 5px;
  left: 0px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {      

  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  width: 100%;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;  
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -3px;
  left: -3px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  left: -19px;
  bottom: 16px;
  transform: rotate(39deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -3px;
  right: -3px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -2px;
  bottom: 17px;
  transform: rotate(-44deg);
}
</style>